import * as R from 'ramda'
import React from 'react'
import styled, { css } from 'styled-components'
import { FaMedal } from 'react-icons/fa'

const defaultProps = {}
const propTypes = {}

const StyledMedal = styled(FaMedal)`
  color: ${({ color }) => color};
  ${({ medalstyles }) => medalstyles}
  width: fit-content;
`
export const KarmaValue = styled.div`
  ${({ theme }) => theme.font.h3}
  color: ${({ theme }) => theme.colors.accentP};
`
const Container = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  position: relative;
`

const bigStyles = css`
  font-size: 32px;
  height: 32px;
`
const smallStyles = css`
  font-size: 16px;
  height: 16px;
  position: absolute;
  left: calc(50% - 8px);
  top: 0;
  &:nth-child(2) {
    top: 17px;
    left: 0;
  }
  &:nth-child(3) {
    top: 17px;
    left: 17px;
  }
`

const levevList = [
  {
    desc: 'Level 9',
    color: '#FFD700',
    count: 3,
    min: 1000,
    medalstyles: smallStyles,
  },
  {
    desc: 'Level 8',
    color: '#FFD700',
    count: 2,
    min: 750,
    medalstyles: smallStyles,
  },
  {
    desc: 'Level 7',
    color: '#FFD700',
    count: 1,
    min: 500,
    medalstyles: bigStyles,
  },
  {
    desc: 'Level 6',
    color: '#C0C0C0',
    count: 3,
    min: 380,
    medalstyles: smallStyles,
  },
  {
    desc: 'Level 5',
    color: '#C0C0C0',
    count: 2,
    min: 320,
    medalstyles: smallStyles,
  },
  {
    desc: 'Level 4',
    color: '#C0C0C0',
    count: 1,
    min: 160,
    medalstyles: bigStyles,
  },
  {
    desc: 'Level 3',
    color: '#007CFF',
    count: 3,
    min: 100,
    medalstyles: smallStyles,
  },
  {
    desc: 'Level 2',
    color: '#007CFF',
    count: 2,
    min: 50,
    medalstyles: smallStyles,
  },
  {
    desc: 'Level 1',
    color: '#007CFF',
    count: 1,
    min: 0,
    medalstyles: bigStyles,
  },
]

export const KarmaMedal = ({ value, className, ...rest }) => {
  const levelConfig = R.find(({ min }) => min <= (value || 0))(levevList)

  return (
    <Container className={className}>
      {R.times(
        i => (
          <StyledMedal
            key={`karma-medal-${i}`}
            {...rest}
            medalstyles={levelConfig?.medalstyles}
            color={levelConfig?.color}
          />
        ),
        levelConfig?.count || 0
      )}
    </Container>
  )
}

KarmaMedal.defaultProps = defaultProps
KarmaMedal.propTypes = propTypes

export default KarmaMedal
