import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 416px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
const Header = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 60px;
  max-width: 416px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  z-index: 9;
  ${({ theme }) => theme.media.phone} {
    padding: 0 16px;
  }
`
const Content = styled.div`
  overflow-y: auto;
  flex: 1;
  padding: 10px 16px 0;
  width: 100%;
`
const Title = styled.div`
  margin-bottom: 16px;
  ${({ theme }) => theme.font.h3};
`
const Desc = styled.div``

const StepLayout = props => {
  const { children, title, desc } = props

  return (
    <Container {...props}>
      <Header>
        {title && <Title>{title}</Title>}
        {desc && <Desc>{desc}</Desc>}
      </Header>
      <Content>{children}</Content>
    </Container>
  )
}

export default StepLayout
