import React, { useState } from 'react'
// import { useRouter } from 'next/router'
import styled from 'styled-components'
import IconButton from '@mui/material/IconButton'

import { Input } from 'components/elements'

import { MdSearch, MdOutlineClose } from 'react-icons/md'

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`

const CloseIcon = styled(MdOutlineClose)`
  font-size: 24px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`

const SearchIcon = styled(MdSearch)`
  font-size: 24px;
  min-width: 24px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`

export const GoogleSearchInput = ({ onClose }) => {
  const [searchTerm, setTerm] = useState('')
  // const router = useRouter()

  const handleChange = event => {
    setTerm(event.target.value)
  }

  const onKeyPress = e => {
    if (e.keyCode == 13) {
      if (searchTerm) {
        // router.push(`/search_gcse?q=${searchTerm}`, null, { shallow: false })
        // window.location = `/search_gcse?q=${searchTerm}`
        window.location = `/search?t=articles&q=${searchTerm}`
      }
    }
  }

  return (
    <SearchContainer>
      <Input
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        variant="outlined"
        value={searchTerm}
        onChange={handleChange}
        onKeyDown={onKeyPress}
        color="error"
        autoFocus
        InputProps={{
          startAdornment: (
            <SearchIcon
              onClick={() => {
                if (searchTerm) {
                  window.location = `/search?t=articles&q=${searchTerm}`
                }
              }}
            />
          ),
          endAdornment: (
            <IconButton
              type="submit"
              sx={{ p: '10px' }}
              aria-label="search"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ),
        }}
      />
    </SearchContainer>
  )
}

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  box-shadow: 0 1px 0 0 rgb(139 141 157 / 5%), 0 0 1px 0 rgb(65 71 108 / 15%);
`
export const Divider = styled.div`
  width: 1px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`
