import styled, { css } from 'styled-components'

const SmallStyles = css`
  ${({ theme }) => theme.font.body2}
  border-radius: 13px;
`
const MediumStyles = css`
  ${({ theme }) => theme.font.body}
  border-radius: 16px;
`

const sizeMap = {
  small: SmallStyles,
  medium: MediumStyles,
}

export const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 4px 12px;
  cursor: pointer;
  border: solid 1px transparent;
  ${({ size }) => sizeMap[size] || sizeMap.medium}

  &::before {
    content: ${({ customLabels }) => `'${customLabels.follow}'`};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.grey};
    border-color: ${({ theme }) => theme.colors.grey};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.grey};
      border-color: ${({ theme }) => theme.colors.grey};

      &::before {
        content: ${({ customLabels }) => `'${customLabels.following}'`};
      }

      &:hover {
        &::before {
          content: ${({ customLabels }) => `'${customLabels.unfollow}'`};
        }
      }
    `}
`
