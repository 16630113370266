import * as R from 'ramda'
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Image from 'next/image'
import Link from 'next/link'
import Script from 'next/script'
import { useRouter } from 'next/router'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Collapse from '@mui/material/Collapse'
import Fade from '@mui/material/Fade'
import { MdSearch } from 'react-icons/md'
import { useSession } from 'context/Session'
import { Button, Modal } from 'components/elements'
import { withSizes } from 'components/helpers'
import AuthSteps from 'components/collections/AuthSteps'
import api from 'shared/api'
import keys from 'shared/keys'

import ConfirmEmailStripe from './ConfirmEmailStripe'

import * as S from './Header.styles'

const defaultProps = {}
const propTypes = {}

const Container = styled.div`
  position: relative;
  height: 73px;
  min-height: 73px;
  width: 100%;
  padding: 0 160px;
  background: ${({ theme }) => theme.colors.white};

  /* box-shadow: 0 -1px 0 0 rgb(139 141 157 / 5%) inset,
    0 0 2px 0 rgb(65 71 108 / 15%) inset; */
  box-shadow: 0 1px 0 0 rgb(139 141 157 / 5%), 0 0 1px 0 rgb(65 71 108 / 15%);
  display: flex;
  justify-content: center;
  &::after,
  &::before {
    box-sizing: inherit;
  }
  ${({ theme }) => theme.media.tablet} {
    padding: 0 32px;
  }
  ${({ theme }) => theme.media.phone} {
    padding: 0 16px;
  }
`

const ImageWrapper = styled.a`
  width: 150px;
  height: fit-content;
  position: relative;
  cursor: pointer;
  padding: 15px;
  ${({ theme }) => theme.media.phone} {
    width: 80px;
    padding: 0;
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 1440px;
`

const GetStartedButton = styled(Button)`
  height: unset;
`
const StyledAvatar = styled.div`
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
  max-width: 56px;
  max-height: 56px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
`
const ImgProfile = styled.div`
  height: 100%;
  width: 100%;
  ${({ src }) =>
    src &&
    css`
      background-image: ${`url(${src})`};
      background-size: cover;
      background-position: center;
    `};
`
const AvatarButton = ({
  user,
  accessToken,
  clearSession,
  isMobile,
  isStaff,
  openSetupModal,
}) => {
  const router = useRouter()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleWriteClick = asStaff => {
    handleClose()

    if (!user?.isProfileComplete || !user?.isEmailConfirmed) {
      openSetupModal()
    } else if (!user?.viewedValueProposition) {
      router.push('/publish-on-trialsite')
    } else {
      router.push(`/write${asStaff ? '?staff=true' : ''}`)
    }
  }

  return (
    <>
      <StyledAvatar onClick={handleClick}>
        {user?.profile?.profileImage ? (
          <ImgProfile
            data-test="icon"
            src={user?.profile?.profileImage}
            alt="article"
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            quality={90}
            priority
            loading="eager"
          />
        ) : (
          user?.profile && R.head(user?.profile?.username || 'U')
        )}
      </StyledAvatar>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {isMobile && <MenuItem onClick={handleWriteClick}>Write</MenuItem>}
        {isMobile && isStaff && (
          <MenuItem onClick={() => handleWriteClick(true)}>
            Write as TSN
          </MenuItem>
        )}
        {isMobile && (
          <MenuItem
            onClick={() => {
              router.push('/questions')
              handleClose()
            }}
          >
            {'Q&A'}
          </MenuItem>
        )}
        {isMobile && (
          <MenuItem
            onClick={() => {
              router.push('/groups')
              handleClose()
            }}
          >
            {'Groups'}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            router.push('/dashboard')
            handleClose()
          }}
        >
          Dashboard
        </MenuItem>
        <MenuItem
          onClick={() => {
            router.push('/settings')
            handleClose()
          }}
        >
          Settings
        </MenuItem>
        <MenuItem
          onClick={() => {
            api
              .get('/billing/billing-portal', {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              })
              .then(({ data }) => {
                router.push(data?.billingPortalUrl)
              })
            handleClose()
          }}
        >
          Billing
        </MenuItem>
        <MenuItem onClick={clearSession}>Logout</MenuItem>
      </Menu>
    </>
  )
}

const WriteButton = ({ isStaff, user, openSetupModal }) => {
  const router = useRouter()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    if (!user?.isProfileComplete || !user?.isEmailConfirmed) {
      openSetupModal()
    } else if (!user?.viewedValueProposition) {
      router.push('/publish-on-trialsite')
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (isStaff) {
    return (
      <>
        <Button
          onClick={handleClick}
          style={{ marginRight: 23 }}
          variant="text"
        >
          Write an article
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            onClick={() => {
              router.push('/write')
              handleClose()
            }}
          >
            Write
          </MenuItem>
          <MenuItem
            onClick={() => {
              router.push('/write?staff=true')
              handleClose()
            }}
          >
            Write as TSN
          </MenuItem>
        </Menu>
      </>
    )
  }

  return (
    <Button
      onClick={() => {
        if (!user?.isProfileComplete || !user?.isEmailConfirmed) {
          openSetupModal()
        } else if (!user?.viewedValueProposition) {
          router.push('/publish-on-trialsite')
        } else {
          router.push('/write')
        }
      }}
      style={{ marginRight: 23 }}
      variant="text"
    >
      Write an article
    </Button>
  )
}

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`

const StickyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 99;
`

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
`
const SearchIcon = styled(MdSearch)`
  font-size: 24px;
`
const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;

  ${({ isSearching }) =>
    isSearching &&
    css`
      ${({ theme }) => theme.media.phone} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    `}
`

const SearchOptionComponent = ({
  isMobile,
  isSearching,
  setIsSearching,
  ...rest
}) => {
  return (
    <SearchContainer isSearching={isSearching} {...rest}>
      <Collapse
        orientation="horizontal"
        in={isSearching}
        unmountOnExit
        timeout={100}
      >
        <S.GoogleSearchInput onClose={() => setIsSearching(false)} />
      </Collapse>
      <Collapse orientation="horizontal" in={!isSearching}>
        <Button
          onClick={() => setIsSearching(b => !b)}
          variant="text"
          startIcon={!isMobile && <SearchIcon />}
        >
          {isMobile ? <SearchIcon /> : 'Search'}
        </Button>
      </Collapse>
    </SearchContainer>
  )
}
const SearchOption = withSizes(SearchOptionComponent)

export const Header = ({ isMobile }) => {
  const router = useRouter()
  const [isOpen, setOpen] = useState(false)
  const [stepsConfig, setStepsConfig] = useState({})
  const [modalConfig, setModalConfig] = useState({})
  const { user, accessToken, clearSession, verifyPermission } = useSession()
  const { profile } = user || {}
  const [isSearching, setIsSearching] = useState(false)

  const isStaff = verifyPermission(['Admin', 'Staff', 'SuperAdmin'])

  useEffect(() => {
    const displayPreferences = JSON.parse(
      localStorage.getItem(keys.DISPLAY_PREFERENCES)
    )
    if (profile && (!profile?.username || displayPreferences)) {
      setStepsConfig({
        defaultStep: !profile?.username ? 'username' : 'preference',
        stepCustomConfig: {
          username: { setModalConfig },
        },
      })
      setModalConfig({ disableClose: !profile?.username })
      setOpen(true)
    }
  }, [accessToken, profile])

  const openSetupModal = () => {
    setStepsConfig({
      defaultStep: !user?.isProfileComplete ? 'setup' : 'welcome',
      defaultValues: user?.profile,
      stepCustomConfig: {
        setup: {
          desc: 'Complete your profile setup before writing an article',
        },
      },
    })
    setOpen(true)
  }

  const modalClose = () => {
    setOpen(false)
    setStepsConfig({})
    setModalConfig({})
  }

  const config = {
    user,
    accessToken,
    clearSession,
    isStaff,
    openSetupModal,
    isMobile,
  }

  return (
    <>
      <StickyWrapper>
        {typeof window !== 'undefined' && (
          <Script
            async
            src="https://cse.google.com/cse.js?cx=028315ce5e1b830ba"
          ></Script>
        )}
        <Container>
          <Content>
            <Link href="/" passHref>
              <ImageWrapper>
                <Image
                  data-test="icon"
                  src="/icons/tsn-logo.svg"
                  alt="tsn"
                  priority
                  width={300}
                  height={143}
                  layout="responsive"
                />
              </ImageWrapper>
            </Link>
            <HeaderContent>
              <SearchOption
                style={{ marginRight: 0 }}
                isSearching={isSearching}
                setIsSearching={setIsSearching}
              />
              {!isSearching && !isMobile && (
                <Fade in={!isSearching} timeout={400} appear unmountOnExit>
                  <Button
                    onClick={() => {
                      router.push('/questions')
                    }}
                    style={{ marginRight: user ? 0 : 23 }}
                    variant="text"
                  >
                    {'Q&A'}
                  </Button>
                </Fade>
              )}
              {!isSearching && !isMobile && (
                <Fade in={!isSearching} timeout={400} appear unmountOnExit>
                  <Button
                    onClick={() => {
                      router.push('/groups')
                    }}
                    style={{ marginRight: user ? 0 : 23 }}
                    variant="text"
                  >
                    {'Groups'}
                  </Button>
                </Fade>
              )}
              {!isSearching && (
                <Fade in={!isSearching} timeout={400} appear unmountOnExit>
                  {user ? (
                    <ActionsContainer>
                      {false && !isMobile && <WriteButton {...config} />}
                      <AvatarButton {...config} />
                    </ActionsContainer>
                  ) : (
                    <GetStartedButton
                      style={{ height: 'unset' }}
                      onClick={() => setOpen(true)}
                    >
                      Sign In
                    </GetStartedButton>
                  )}
                </Fade>
              )}
            </HeaderContent>
            <Modal
              isOpen={isOpen}
              close={modalClose}
              closeOnOutside={false}
              {...modalConfig}
            >
              <AuthSteps {...stepsConfig} />
            </Modal>
          </Content>
        </Container>
        <ConfirmEmailStripe />
      </StickyWrapper>
      {isMobile && (
        <S.SubHeader>
          <S.Divider />
          <Button
            onClick={() => {
              router.push('/questions')
            }}
            style={{ width: '100%' }}
            variant="text"
          >
            {'Q&A'}
          </Button>
          <S.Divider />
          <Button
            onClick={() => {
              router.push('/groups')
            }}
            style={{ width: '100%' }}
            variant="text"
          >
            {'Groups'}
          </Button>
          <S.Divider />
        </S.SubHeader>
      )}
    </>
  )
}

Header.defaultProps = defaultProps
Header.propTypes = propTypes

export default withSizes(Header)
