import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useRouter } from 'next/router'
import { useSession } from 'context/Session'
import { useLayout } from 'context/Layout'
import { Loading } from 'components/elements'
import api from 'shared/api'

const defaultProps = {}
const propTypes = {}

const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 35px;
  background: ${({ theme }) => theme.colors.accentY};
  text-align: center;
  padding: 0 15px;
`
const ResendLink = styled.div`
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      text-decoration: unset;
    `}
`
const UpgradeLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

export const Stripe = () => {
  const router = useRouter()
  const [isSending, setIsSending] = useState(false)
  const [doubleSent, setDoubleSent] = useState(false)
  const { user, accessToken, verifyPermission } = useSession()
  const { layoutConfig } = useLayout()
  const returnUrl = SITE_URL + router.asPath

  const isStaff = verifyPermission(['Admin', 'Staff', 'SuperAdmin'])

  const resendEmail = async () => {
    if (!doubleSent) {
      setIsSending(true)
      api
        .post('/users/resend-confirm-email', null, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(() => {
          setIsSending(false)
          setDoubleSent(true)
        })
    }
  }

  const { header } = layoutConfig || {}
  const { Stripe } = header || {}

  const subPersonal = async () => {
    const { data } = await api.post(
      '/billing/checkout',
      {
        priceId: process.env.NEXT_PUBLIC_PERSONAL_ID,
        returnUrl,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    router.push(data?.checkoutUrl || '/')
  }

  if (user && !user?.isEmailConfirmed) {
    return (
      <>
        <Container>
          Please confirm your email.
          {isSending ? (
            <Loading size={20} style={{ marginLeft: 5 }} />
          ) : (
            <ResendLink disabled={doubleSent} onClick={resendEmail}>
              {doubleSent ? 'Email sent' : 'Resend'}
            </ResendLink>
          )}
        </Container>
        {Stripe && <Stripe />}
      </>
    )
  }

  if (user && !user?.isMember && !isStaff) {
    return (
      <>
        <Container>
          <div>
            You are a free subscriber. If you want to access all content,
            consider an&nbsp;
            <UpgradeLink onClick={subPersonal}>Upgrade</UpgradeLink>.
          </div>
        </Container>
        {Stripe && <Stripe />}
      </>
    )
  }

  return null
}

Stripe.defaultProps = defaultProps
Stripe.propTypes = propTypes

export default Stripe
