import { css } from 'styled-components'

const desktop = css`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
`

const h1 = css`
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 72px;
  ${({ theme }) => theme.media.phone} {
    font-size: 32px;
    line-height: 40px;
  }
`

const h2 = css`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  ${({ theme }) => theme.media.phone} {
    font-size: 24px;
    line-height: 32px;
  }
`

const h3 = css`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
`
const h4 = css`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
`

const body = css`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
`

const body2 = css`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.5px;
`

const label = css`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`

const bold = css`
  font-weight: 600;
`

const small = css`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
`

export const FontStyles = {
  desktop,
  h1,
  h2,
  h3,
  h4,
  body,
  body2,
  label,
  bold,
  small,
}
