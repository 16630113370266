import * as R from 'ramda'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'
import api from 'shared/api'
import keys from 'shared/keys'

const SessionContext = createContext()

const KEY = 't'
const SECRET = 's'

export function SessionProvider({ children }) {
  const router = useRouter()
  const [cookies, setCookie, removeCookie] = useCookies([KEY])
  const [user, setUser] = useState(cookies[KEY])
  const { roles = [] } = user || {}

  const setSession = data => {
    setCookie(KEY, JSON.stringify(data), {
      path: '/',
      maxAge: data?.maxAge || 86400,
    })
    setUser(data)
  }

  const clearSession = () => {
    removeCookie(KEY, { path: '/' })
    window.location = '/'
  }

  const loginEmail = async (formData, create) => {
    if (!create) {
      return api
        .post('/auth/email/sign-in', formData, null)
        .then(function ({ data }) {
          setSession(data)
          router.reload()
          return { data }
        })
    }
    return api.post('/auth/sign-up', formData, null).then(function ({ data }) {
      setSession(data)
      localStorage.setItem(keys.DISPLAY_PREFERENCES, true)
      router.reload()
    })
  }

  const getRequestTwitterToken = async () => {
    const { data } = await api.post('/auth/twitter/request-token')
    setCookie(SECRET, data.sessionId, { path: '/' })
    router.push(
      `https://api.twitter.com/oauth/authorize?oauth_token=${data.token}`
    )
  }

  const loginFacebook = onSuccess => {
    return window?.FB.login(
      function (response) {
        if (response.status === 'connected') {
          onSuccess()
          const config = {
            headers: {
              Authorization: `Bearer ${response.authResponse.accessToken}`,
            },
          }
          return api
            .post('/auth/facebook', null, config)
            .then(function ({ data }) {
              setSession(data)
              onSuccess()
              router.reload()
            })
        }
      },
      { scope: 'public_profile,email' }
    )
  }

  function loginGoogle(googleUser, onSuccess) {
    // console.log('>>>>>>googleUser', googleUser)
    // console.log('Google!')
    // const profile = googleUser.getBasicProfile()
    // console.log('ID: ' + profile.getId()) // Do not send to your backend! Use an ID token instead.
    // console.log('Name: ' + profile.getName())
    // console.log('Image URL: ' + profile.getImageUrl())
    // console.log('Email: ' + profile.getEmail()) // This is null if the 'email' scope is not present.
    const id_token = googleUser.getAuthResponse().id_token

    api
      .post('/auth/google', { access_token: id_token })
      .then(function ({ data }) {
        setSession(data)
        onSuccess && onSuccess()
        router.reload()
      })
    // console.log(id_token)
  }

  const loadProfile = async () => {
    try {
      const { data: introspect } = await api.get('/auth/introspect', {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      })
      const response = await api.get('/users/profile', {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      })

      const { data } = response

      const updatedUser = R.omit(['accessToken'], introspect)

      setUser(u => ({ ...u, ...updatedUser, profile: data }))
      return response
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error(error)
      throw error
    }
  }

  const verifyPermission = permissions =>
    R.any(x => R.includes(x)(permissions))(roles)

  const resendConfirmationEmail = () => {
    return api.post('/users/resend-confirm-email', null, {
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
      },
    })
  }

  const loadGroups = async username => {
    const { data: list } = await api.get(
      `/public/profile/${username || user?.profile?.username}/groups`,
      {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      }
    )

    return list
  }

  useEffect(() => {
    if (cookies[KEY]) {
      setUser(cookies[KEY])
    }
  }, [cookies[KEY]])

  useEffect(() => {
    if (user?.accessToken) {
      loadProfile()
    }
  }, [user?.accessToken])

  return (
    <SessionContext.Provider
      value={{
        user,
        setSession,
        accessToken: user?.accessToken,
        clearSession,
        loginFacebook,
        loginGoogle,
        loginEmail,
        loadProfile,
        getRequestTwitterToken,
        verifyPermission,
        resendConfirmationEmail,
        loadGroups,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export function useSession() {
  return useContext(SessionContext)
}
