import React from 'react'
import { useGoogleOneTapLogin } from 'react-google-one-tap-login'
import { useSession } from 'context/Session'

import Header from './components/Header'
import Footer from './components/Footer'

import * as C from './CoreLayout.styles'

const oauthEndpointURL = 'https://oauth2.googleapis.com/tokeninfo?id_token='

const OneTap = ({ children = null }) => {
  const { loginGoogle } = useSession()
  useGoogleOneTapLogin({
    googleAccountConfigs: {
      callback: data => {
        if (data?.credential) {
          fetch(`${oauthEndpointURL}${data.credential}`)
            .then(resp => {
              if (resp?.status === 200 && resp?.json) {
                return resp.json()
              } else {
                throw new Error('Something went wrong')
              }
            })
            .then(() => {
              loginGoogle({
                getAuthResponse: () => ({ id_token: data.credential }),
              })
            })
            .catch(error => {
              throw error
            })
        }
      },
      client_id: process.env.NEXT_PUBLIC_GOOGLE_APP_ID,
    },
  })
  return children
}

const CoreLayout = props => {
  const { children, disableFooter } = props
  const { user } = useSession()

  return (
    <>
      <C.Container>
        {typeof window !== 'undefined' && !user && <OneTap />}
        <Header {...props} />
        <C.Content>{children}</C.Content>
        {!disableFooter && <Footer {...props} />}
      </C.Container>
    </>
  )
}

export default CoreLayout
