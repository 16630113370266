import api from 'shared/api'

export const loadTags = async ({ accessToken, name }) => {
  if (!name) {
    return { list: [] }
  }
  const { data: list } = await api.get(`/tags?name=${name}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return { list }
}

export const loadTop20 = async () => {
  const { data: list } = await api.get('/public/top-20-topics')

  return { list }
}

export const followTopic = async ({ accessToken, slug }) => {
  await api.post(`/users/follow-tag/${slug}`, null, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export const unfollowTopic = async ({ accessToken, slug }) => {
  await api.delete(`/users/follow-tag/${slug}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}
