import React, { useEffect } from 'react'
import Head from 'next/head'
import Script from 'next/script'
import Router from 'next/router'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import Slide from '@material-ui/core/Slide'
import { SnackbarProvider } from 'notistack'
import { QueryClient, QueryClientProvider } from 'react-query'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import 'react-medium-image-zoom/dist/styles.css'
import { Hydrate } from 'react-query/hydration'
import { StyledThemeProvider } from 'components/layouts/Theme'
import { CoreLayout } from 'components/layouts'
import { SessionProvider } from 'context/Session'
import { LayoutProvider } from 'context/Layout'

import NotFound from 'routes/Statics/404'

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const isProduction = process.env.NODE_ENV === 'production'

function MyApp({ Component, pageProps }) {
  const { seoConfig } = pageProps
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }, [])

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
      },
    },
  })

  const { errorCode, errorLog } = pageProps

  // eslint-disable-next-line no-console
  errorLog && console.log('Something went wrong: ', errorLog)

  // const facebookScriptUrl = `https://connect.facebook.net/pt_BR/sdk.js#xfbml=1&version=v12.0&appId=${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}&autoLogAppEvents=1`

  return (
    <StyledEngineProvider injectFirst>
      <Head>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        ></meta>
        <title>
          {seoConfig?.title ||
            'TrialSiteNews | Independent Censorship-Free Media for Health & Biomedical Research'}
        </title>
        <meta
          name="description"
          content="Transparent coverage of clinical research"
        />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta
          name="og:title"
          content={
            seoConfig?.title ||
            'TrialSiteNews | Independent Censorship-Free Media for Health & Biomedical Research'
          }
        ></meta>
        {seoConfig?.image && (
          <meta property="og:image" content={seoConfig?.image} />
        )}
        {typeof window !== 'undefined' && window?.location && (
          <meta property="og:url" content={window.location.href} />
        )}
        <meta
          property="og:description"
          content={
            seoConfig?.content || 'Transparent coverage of clinical research'
          }
        />
      </Head>
      <Script async src="https://js.stripe.com/v3/buy-button.js" />
      {isProduction && (
        <>
          <Script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-VQ5K1HWZ1P"
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-VQ5K1HWZ1P');
              `,
            }}
          />
        </>
      )}
      {isProduction && (
        <>
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js.hs-scripts.com/4652048.js"
          ></script>
        </>
      )}
      {/* <Script
        async
        defer
        crossorigin="anonymous"
        src={facebookScriptUrl}
        nonce="OIHuOnDP"
      /> */}
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ containerAnchorOriginTopCenter: 'z-alert' }}
        TransitionComponent={Slide}
      >
        <LayoutProvider>
          <SessionProvider>
            <StyledThemeProvider>
              <QueryClientProvider client={queryClient}>
                <Hydrate state={pageProps.dehydratedState}>
                  <CoreLayout {...pageProps}>
                    {errorCode === 404 ? (
                      <NotFound />
                    ) : (
                      <Component {...pageProps} />
                    )}
                  </CoreLayout>
                </Hydrate>
              </QueryClientProvider>
            </StyledThemeProvider>
          </SessionProvider>
        </LayoutProvider>
      </SnackbarProvider>
    </StyledEngineProvider>
  )
}

export default MyApp
