import React from 'react'
import styled, { css } from 'styled-components'
import { MdClose } from 'react-icons/md'

const defaultProps = {}
const propTypes = {}

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryL};
  border-radius: 4px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  height: fit-content;
  transition: all 0.2s ease-in-out;

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
    `}
`

const Desc = styled.div`
  ${({ theme }) => theme.font.body2};
`

const RemoveIcon = styled(MdClose)`
  margin-left: 8px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

export const Tag = ({ label, onRemove, disabled, ...rest }) => {
  return (
    <Container {...rest}>
      <Desc>{label}</Desc>
      {onRemove && (
        <RemoveIcon
          disabled={disabled}
          onClick={() => !disabled && onRemove()}
        />
      )}
    </Container>
  )
}

Tag.defaultProps = defaultProps
Tag.propTypes = propTypes

export default Tag
