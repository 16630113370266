import React, { useState } from 'react'
import styled from 'styled-components'
// import T from 'prop-types'
import Input from '../Input'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MuiDatePicker from '@mui/lab/DatePicker'

const defaultProps = {}
const propTypes = {}

const StyledDatePicker = styled(MuiDatePicker)`
  margin-top: 0 !important;
`

export const DatePicker = () => {
  const [value, setValue] = useState(null)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledDatePicker
        label="Basic example"
        value={value}
        onChange={newValue => {
          setValue(newValue)
        }}
        renderInput={params => <Input {...params} />}
      />
    </LocalizationProvider>
  )
}

DatePicker.defaultProps = defaultProps
DatePicker.propTypes = propTypes

export default DatePicker
