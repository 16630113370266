import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Button } from 'components/elements'
import { useSession } from 'context/Session'

import { sendConfirmEmail } from './modules/datasource'

const defaultProps = {}
const propTypes = {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 296px;
  margin: 0 auto;
  text-align: center;
  height: 100%;
  min-height: 500px;
  max-height: 100vh;
  ${({ theme }) => theme.media.phone} {
    padding: 0;
    justify-content: center;
    margin-top: -62px;
  }
`

const Title = styled.div`
  margin-bottom: 16px;
  ${({ theme }) => theme.font.h3};
`
const Desc = styled.div``

const StepButton = styled(Button)`
  width: 296px;
  margin-top: 32px;
  & + & {
    margin-top: 8px;
  }
`

export const Welcome = ({ close }) => {
  const router = useRouter()
  const [doubleSent, setDoubleSent] = useState(false)
  const { user, accessToken, loadProfile } = useSession()

  useEffect(() => {
    loadProfile()
  }, [])

  return (
    <Container>
      <Title>
        Welcome, {user?.profile?.firstName || user?.profile?.username}.{' '}
      </Title>
      {!user?.isEmailConfirmed && (
        <Desc>Please check your email to confirm your email address</Desc>
      )}
      <StepButton
        onClick={() => {
          if (user?.isEmailConfirmed) {
            router.push('/publish-on-trialsite')
            close()
          } else {
            sendConfirmEmail({ accessToken })
            setDoubleSent(true)
          }
        }}
        disabled={doubleSent}
      >
        {user?.isEmailConfirmed ? 'Get Started' : 'Resend email confirmation'}
      </StepButton>
    </Container>
  )
}

Welcome.defaultProps = defaultProps
Welcome.propTypes = propTypes

export default Welcome
