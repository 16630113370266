import React, { useState } from 'react'
import styled from 'styled-components'
import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'
import { getAlphaRgb } from 'components/layouts'
import { useSession } from 'context/Session'

const defaultProps = {
  options: [],
}
const propTypes = {}

const StyledTabs = styled(MuiTabs)`
  &.MuiTabs-root {
    margin-bottom: -1px;
  }
  .MuiButtonBase-root {
    text-transform: none;
  }
  .Mui-selected {
    ${({ theme }) => theme.font.bold};
  }

  ${({ theme }) => theme.media.phone} {
    .MuiButtonBase-root {
      padding: 10px;
      font-size: 12px;
      min-width: 45px;
    }
  }
`

const StyledTabsHeader = styled.div`
  border-bottom: solid 1px ${({ theme }) => getAlphaRgb(theme.colors.grey, 0.5)};
  position: sticky;
  top: ${({ needConfirmEmail }) => (needConfirmEmail ? '108px' : '73px')};
  z-index: 9;
  background: ${({ theme }) => theme.colors.lightGrey};
`

export const TabsHeader = props => {
  const { user } = useSession()
  const needConfirmEmail = user && !user?.isEmailConfirmed
  return <StyledTabsHeader needConfirmEmail={needConfirmEmail} {...props} />
}

export const Tabs = ({ options, onChange, defaultValue, ...rest }) => {
  const [tab, setTab] = useState(defaultValue)
  const handleChange = (_, value) => {
    setTab(value)
    onChange && onChange(value)
  }

  return (
    <StyledTabs
      value={tab}
      onChange={handleChange}
      textColor="primary"
      indicatorColor="primary"
      aria-label="tabs"
      {...rest}
    >
      {options.map(
        ({ value, label }) =>
          label && <MuiTab key={`tab-${value}`} value={value} label={label} />
      )}
    </StyledTabs>
  )
}

Tabs.defaultProps = defaultProps
Tabs.propTypes = propTypes
Tabs.TabsHeader = TabsHeader

export default Tabs
