import React from 'react'
import MuiButton from '@mui/lab/LoadingButton'
import styled from 'styled-components'
// import MuiButton from '@mui/material/Button'

const defaultProps = {
  variant: 'contained',
  disableElevation: true,
}
const propTypes = {}

const StyledButton = styled(MuiButton)`
  height: 56px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
  text-transform: none;
  border-radius: 100px;
  padding: 6px 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
`

const Button = React.forwardRef((props, ref) => {
  return <StyledButton {...props} ref={ref} />
})

Button.defaultProps = defaultProps
Button.propTypes = propTypes
Button.displayName = 'Button'

export default Button
