import React from 'react'
import { CircularProgress } from '@material-ui/core'

const defaultProps = {}
const propTypes = {}

export const Loading = props => {
  return <CircularProgress {...props} />
}

Loading.defaultProps = defaultProps
Loading.propTypes = propTypes

export default Loading
