import styled from 'styled-components'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; */
  /* overflow: hidden; */
`
export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.lightGrey};
  /* overflow-y: auto;
  max-height: 100vh; */
`
