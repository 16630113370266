import React from 'react'
import MuiPagination from '@mui/material/Pagination'

const defaultProps = {}
const propTypes = {}

export const Pagination = props => {
  return <MuiPagination {...props} />
}

Pagination.defaultProps = defaultProps
Pagination.propTypes = propTypes

export default Pagination
