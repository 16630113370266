import React from 'react'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'
// import T from 'prop-types'

const defaultProps = {
  type: 'default',
  variant: 'outlined',
}
const propTypes = {}

export const StyledInput = styled(TextField)``

export const Input = props => {
  return <StyledInput {...props} />
}

Input.defaultProps = defaultProps
Input.propTypes = propTypes

export default styled(Input)``
