import React from 'react'
import styled, { css } from 'styled-components'
import Link from 'next/link'
import { BsFacebook, BsTwitter, BsYoutube } from 'react-icons/bs'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 24px 160px;
  gap: 20px;
  ${({ theme }) => theme.media.desktop} {
    padding: 24px 60px;
  }
  ${({ theme }) => theme.media.tablet} {
    padding: 24px 32px;
  }
  ${({ theme }) => theme.media.phone} {
    padding: 24px 16px;
    flex-direction: column;
  }
`

const ItemsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: -20px;
  flex-wrap: wrap;
  ${({ theme }) => theme.media.phone} {
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
`
const Item = styled.div`
  margin: 20px;
`
const ItemTitle = styled.div`
  ${({ theme }) => theme.font.h4};
  margin-bottom: 16px;
`
const ItemList = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.white};
  font-weight: normal;
  text-decoration: none;
  width: fit-content;
`

export const FooterItems = ({ items }) => {
  return (
    <ItemsContainer>
      {items.map(({ label, items: subItems }) => {
        return (
          <Item key={label}>
            <ItemTitle>{label}</ItemTitle>
            <ItemList>
              {subItems.map(({ label, href, linkProps }) => {
                return (
                  <Link href={href} key={label} passHref>
                    <StyledLink {...linkProps}>{label}</StyledLink>
                  </Link>
                )
              })}
            </ItemList>
          </Item>
        )
      })}
    </ItemsContainer>
  )
}

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: normal;
  min-width: max-content;

  ${({ theme }) => theme.media.phone} {
    align-items: flex-start;
    margin-top: 20px;
  }
`
const InfoTitle = styled.div`
  ${({ theme }) => theme.font.h3};
`
export const TrialSiteInfo = () => {
  return (
    <InfoContainer>
      <InfoTitle>TrialSiteNews</InfoTitle>
      <div>159 W Broadway, Suite 200</div>
      <div>Salt Lake City, UT 84101</div>
    </InfoContainer>
  )
}

export const Copyright = styled.div`
  ${({ theme }) => theme.font.h4};
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
  font-weight: normal;
  ${({ theme }) => theme.media.phone} {
    text-align: left;
    padding: 0 16px 16px;
  }
`

export const SocialLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`
const iconStyle = css`
  width: 20px;
  height: 20px;
`
export const YoutubeIcon = styled(BsYoutube)`
  ${iconStyle}
`
export const TwitterIcon = styled(BsTwitter)`
  ${iconStyle}
`
export const FacebookIcon = styled(BsFacebook)`
  ${iconStyle}
`
