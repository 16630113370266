import * as R from 'ramda'
import React from 'react'
import styled from 'styled-components'
import MaterialSelect from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { FormHelperText } from '@mui/material'

const defaultProps = {
  displayEmpty: true,
}
const propTypes = {}

const Select = React.forwardRef((props, ref) => {
  const {
    label,
    options = [],
    showAll,
    style,
    className,
    helperText,
    ...rest
  } = props

  if (!R.isEmpty(options)) {
    return (
      <FormControl style={style} className={className} fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <MaterialSelect ref={ref} {...rest}>
          {showAll && <MenuItem value={undefined}>All</MenuItem>}
          {options.map((item, index) => {
            const { label, value } = item
            return (
              <MenuItem key={index} value={value}>
                {label}
              </MenuItem>
            )
          })}
        </MaterialSelect>

        {helperText && (
          <FormHelperText id="my-helper-text">{helperText}</FormHelperText>
        )}
      </FormControl>
    )
  }

  return <MaterialSelect ref={ref} variant="outlined" {...props} />
})

Select.defaultProps = defaultProps
Select.propTypes = propTypes
Select.Option = MenuItem
Select.displayName = 'Select'

export default styled(Select)`
  background: white;
  .MuiOutlinedInput-input {
    box-sizing: border-box;
  }
  .MuiFormLabel-root {
    background: ${({ theme }) => theme.colors.white};
    padding-left: 4px;
    padding-right: 4px;
  }
`
