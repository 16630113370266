import tinycolor from 'tinycolor2'

const getAlphaRgb = (color, alpha) =>
  tinycolor(color).setAlpha(alpha).toRgbString()

const colors = {
  primary: '#007CFF',
  primaryL: '#CCE5FF',
  error: '#DC0808',
  accentY: '#FAC409',
  black: '#000000',
  grey: '#8D9091',
  grey2: '#DBDBDB',
  lightGrey: '#F5F5F5',
  white: '#FFFFFF',
  accentP: '#A4B0D8',
  accentLilac: '#B3A0FF',
  accentMint: '#09FA95',
}

export { colors, getAlphaRgb }
