import { parse } from 'cookie'
const axios = require('axios')

const baseApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API,
})

export default baseApi

const getUserFromCtx = ctx => {
  const { t } = parse(ctx?.req?.headers?.cookie || '')

  return t ? JSON.parse(t) : null
}

export { getUserFromCtx }
