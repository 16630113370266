import * as R from 'ramda'
import React, { useState, useEffect } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { useQuery } from 'react-query'
import { Button, Loading } from 'components/elements'
import { Form } from 'components/collections'
import { useDebounce } from 'components/helpers/utils'
import { useSession } from 'context/Session'
import keys from 'shared/keys'
import {
  loadTags,
  loadTop20,
  followTopic,
  unfollowTopic,
} from './modules/datasource'
import Step from '../../layout/Step'

import * as S from './Preferences.styles'

const defaultProps = {
  title: 'What you are most interested in?',
}
const propTypes = {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 24px;
  width: 100%;
  ${({ theme }) => theme.media.phone} {
    padding: 32px 0 20px;
  }
`

const StepButton = styled(Button)`
  width: 296px;
  margin-top: 32px;
  & + & {
    margin-top: 8px;
  }
`

const Tags = ({ accessToken }) => {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const { values, setFieldValue } = useFormikContext()
  const { tags = [] } = values

  const debouncedSearchTerm = useDebounce(inputValue, 500)

  const { data: tagsData = {}, isLoading } = useQuery(
    ['topics', { accessToken, debouncedSearchTerm }],
    () => loadTags({ accessToken, name: debouncedSearchTerm }),
    {
      fetchPolicy: 'no-cache',
    }
  )

  const { data: top20Data = {}, isLoading: top20Loading } = useQuery(
    ['top-20', { accessToken }],
    () => loadTop20(),
    {
      fetchPolicy: 'no-cache',
    }
  )

  const { list: tagsList = [] } = tagsData
  const { list: top20List = [] } = top20Data

  return (
    <S.TagsSection>
      <S.TagsTitle>Top 20 topics</S.TagsTitle>
      <S.Top20Container>
        {top20Loading && <Loading style={{ margin: '14px auto' }} />}
        {top20List?.map(({ name, slug }) => (
          <S.CustomFollowTag
            key={`top20-${slug}`}
            name={name}
            slug={slug}
            setFieldValue={setFieldValue}
            tags={tags}
            accessToken={accessToken}
          />
        ))}
      </S.Top20Container>
      <S.TagsTitle>Search for topics you might like</S.TagsTitle>
      <S.TagsContent>
        <Autocomplete
          size="small"
          multiple
          open={open}
          loading={isLoading}
          onOpen={() => {
            setOpen(true)
          }}
          onClose={() => {
            setOpen(false)
          }}
          disableClearable
          renderTags={() => null}
          value={tags}
          isOptionEqualToValue={(option, value) => {
            return option?.slug === value?.slug
          }}
          onChange={(_, value, r) => {
            const cmp = (x, y) => x.slug === y.slug
            if (r === 'removeOption') {
              const option = R.differenceWith(cmp, tags, value)[0]
              unfollowTopic({ accessToken, slug: option?.slug })
            } else {
              const option = R.differenceWith(cmp, value, tags)[0]
              followTopic({ accessToken, slug: option?.slug })
            }
            setFieldValue('tags', value)
          }}
          options={tagsList}
          noOptionsText="Type to search"
          renderInput={params => (
            <S.TagInput
              {...params}
              label="Tag"
              value="lala"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? <Loading size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue)
          }}
          getOptionLabel={o => o?.name}
        />
        <S.TagList>
          {tags?.map(
            item =>
              !R.any(R.propEq('slug', item?.slug), top20List) && (
                <S.TagItem
                  key={`tag-${item?.name}`}
                  label={item?.name}
                  onRemove={() => {
                    unfollowTopic({ accessToken, slug: item?.slug })
                    setFieldValue('tags', R.without([item], tags))
                  }}
                />
              )
          )}
        </S.TagList>
      </S.TagsContent>
    </S.TagsSection>
  )
}

export const Preferences = props => {
  const { close } = props
  const { user } = useSession()

  const submit = async () => {
    close()
  }

  useEffect(() => {
    return () => {
      localStorage.setItem(keys.DISPLAY_PREFERENCES, false)
    }
  }, [])

  return (
    <Step {...props} style={{ maxWidth: 'unset' }}>
      <Form
        onSubmit={submit}
        initialValues={{
          tags: [],
        }}
      >
        <Container>
          <Tags accessToken={user?.accessToken} />
          <StepButton type="submit">Complete</StepButton>
        </Container>
      </Form>
    </Step>
  )
}

Preferences.defaultProps = defaultProps
Preferences.propTypes = propTypes

export default Preferences
