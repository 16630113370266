import styled, { createGlobalStyle } from 'styled-components'
import { MdClose } from 'react-icons/md'

export const Overlay = styled.div`
  z-index: 999;
  background: rgba(196, 196, 196, 0.6);
  height: 100%;
  width: 100%;
`

export const Modal = styled.div`
  max-width: 848px;
  width: 100%;
  position: fixed;
  top: 10%;
  max-height: calc(100% - 20vh);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 35px rgba(0, 0, 0, 0.03);
  ${({ theme }) => theme.media.tablet} {
    padding: 0 30px;
  }
  ${({ theme }) => theme.media.phone} {
    padding: 0;
    max-width: unset;
    max-height: unset;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: unset;
  }
`

export const Header = styled.div`
  padding: 13px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white}};
  ${({ css }) => css};
`

export const Close = styled(MdClose)`
  cursor: pointer;
  right: 10px;
  padding: 4px;
  font-size: 36px;
`

export const Title = styled.div`
  ${({ theme }) => theme.font.h3};
  ${({ theme }) => theme.font.bold};
`

export const Content = styled.div`
  background-color: white;
  overflow: auto;
  min-height: 200px;
  flex: 1;
  ${({ css }) => css};
`

export const Footer = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
`

export const DisableBodyOverflow = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

export const Container = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  z-index: 999;
`
