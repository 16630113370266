import React, { useState } from 'react'
import styled from 'styled-components'
import { useSnackbar } from 'notistack'
import { useSession } from 'context/Session'
import { Button } from 'components/elements'
import { Form } from 'components/collections'
import Step from '../../layout/Step'
import { forgotPassword } from './modules/datasource'

const defaultProps = {}
const propTypes = {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 0 44px;
  max-width: 296px;
  margin: 0 auto;
  position: relative;
  ${({ theme }) => theme.media.phone} {
    padding-top: 32px;
  }
`

const StepButton = styled(Button)`
  width: 296px;
  margin-top: 32px;
  & + & {
    margin-top: 8px;
  }
`

const SentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 300px;
  max-height: 100vh;
`

const SentResetEmail = ({ setStep, formData }) => {
  const [doubleSent, setDoubleSent] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  return (
    <SentContainer>
      <StepButton type="button" onClick={() => setStep('emailIn')}>
        Back to login
      </StepButton>
      <StepButton
        onClick={async () => {
          await forgotPassword(formData)
          setDoubleSent(true)
          enqueueSnackbar('Email has been sent', { variant: 'success' })
        }}
        type="button"
        variant="text"
        disabled={doubleSent}
      >
        Resend an email
      </StepButton>
    </SentContainer>
  )
}

export const EmailPassword = props => {
  const [hasSent, setHasSent] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const { formData, close, setStep } = props
  const { name } = formData
  const { loginEmail } = useSession()

  const submit = async (values, { setErrors }) => {
    setIsFetching(true)
    await loginEmail(values)
      .then(function () {
        close()
      })
      .catch(function () {
        setIsFetching(false)
        setErrors({ password: 'Invalid password' })
      })
  }

  return (
    <Step
      title={hasSent ? 'Check email to reset your password' : `Hi, ${name}`}
      desc={
        hasSent ? `Reset password email is sent to ${formData?.email}` : null
      }
    >
      <Form onSubmit={submit} initialValues={formData}>
        {hasSent ? (
          <SentResetEmail {...props} />
        ) : (
          <Container>
            <Form.Input
              name="password"
              label="Password"
              type="password"
              required
              autoFocus
              inputProps={{
                autocomplete: 'new-password',
              }}
            />
            <StepButton
              disabled={isFetching}
              loading={isFetching}
              type="submit"
            >
              Continue
            </StepButton>
            <StepButton
              onClick={async () => {
                await forgotPassword(formData)
                setHasSent(true)
              }}
              type="button"
              variant="text"
              disabled={isFetching}
            >
              Forgot password?
            </StepButton>
            <StepButton
              onClick={async () => {
                setStep('emailIn')
              }}
              type="button"
              variant="text"
              disabled={isFetching}
            >
              Back
            </StepButton>
          </Container>
        )}
      </Form>
    </Step>
  )
}

EmailPassword.defaultProps = defaultProps
EmailPassword.propTypes = propTypes

export default EmailPassword
