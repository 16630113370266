import React, { createContext, useContext, useState } from 'react'

const LayoutContext = createContext()

export function LayoutProvider({ children }) {
  const [layoutConfig, setLayoutConfig] = useState()

  return (
    <LayoutContext.Provider
      value={{
        layoutConfig,
        setLayoutConfig,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export function useLayout() {
  return useContext(LayoutContext)
}
