import * as R from 'ramda'
import React from 'react'
import styled, { css } from 'styled-components'
import { Input, Tag, FollowButton } from 'components/elements'
import { followTopic, unfollowTopic } from './modules/datasource'

export const TagsSection = styled.div``

export const TagsTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  text-align: center;
`

export const TagsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .MuiAutocomplete-root {
    width: 200px;
  }
  .MuiOutlinedInput-root {
    padding: 7px;
  }

  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 18px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`
export const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin: 16px auto 0;
  max-width: 586px;
`

export const TagItem = styled(Tag)`
  margin: 4px;
`

export const TagInput = styled(Input)``

export const Top20Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 586px;
  margin: -5px auto 20px;
`

const StyledFollowTab = styled(FollowButton)`
  margin: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  border-color: ${({ theme }) => theme.colors.primary};
  &::before {
    content: ${({ desc }) => `'${desc}'`} !important;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.white};
  }

  ${({ isFollowing }) =>
    isFollowing &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.white};
    `}
`

export const CustomFollowTag = ({
  name,
  slug,
  setFieldValue,
  tags,
  accessToken,
}) => {
  const isFollowing = R.any(R.propEq('slug', slug), tags)

  const handleClick = () => {
    if (isFollowing) {
      setFieldValue('tags', R.reject(R.propEq('slug', slug), tags))
      unfollowTopic({ accessToken, slug })
    } else {
      setFieldValue('tags', R.concat([{ name, slug }], tags))
      followTopic({ accessToken, slug })
    }
  }

  return (
    <StyledFollowTab
      desc={name}
      isFollowing={isFollowing}
      onClick={handleClick}
    />
  )
}
