import api from 'shared/api'

export const loadIsFollowing = async ({ tagSlug, username, accessToken }) => {
  if (tagSlug) {
    const { data: isFollowing } = await api.get(
      `/public/tags/${tagSlug}/is-following`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    return isFollowing
  }
  const { data: isFollowing } = await api.get(
    `/public/profile/${username}/is-following`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
  return isFollowing
}

export const follow = async ({ accessToken, username, tagSlug }) => {
  if (tagSlug) {
    await api.post(`/users/follow-tag/${tagSlug}`, null, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    return
  }

  await api.post(`/users/follow/${username}`, null, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export const unfollow = async ({ accessToken, username, tagSlug }) => {
  if (tagSlug) {
    await api.delete(`/users/follow-tag/${tagSlug}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    return
  }

  await api.delete(`/users/follow/${username}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}
