import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'
import { Select } from 'components/elements'

const defaultProps = {}
const propTypes = {}

const StyledSelect = styled(Select)``

export const FormSelect = ({
  name,
  validate,
  required,
  helperText,
  ...rest
}) => {
  const inputValidation = value => {
    if (!validate) {
      return required && !value && 'Required field'
    } else {
      return validate(value)
    }
  }

  return (
    <Field name={name} validate={inputValidation}>
      {({ field, form }) => (
        <StyledSelect
          id={name}
          {...field}
          fullWidth
          onChange={form.handleChange}
          error={form.touched[name] && Boolean(form.errors[name])}
          helperText={(form.touched[name] && form.errors[name]) || helperText}
          disabled={form.isSubmitting}
          {...rest}
        />
      )}
    </Field>
  )
}

FormSelect.defaultProps = defaultProps
FormSelect.propTypes = propTypes

export default FormSelect
