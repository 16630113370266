import * as R from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useSnackbar } from 'notistack'
import { Button } from 'components/elements'
import { Form } from 'components/collections'
import { useSession } from 'context/Session'
import api from 'shared/api'
import Step from '../../layout/Step'

const defaultProps = {
  title: 'What best describes you?',
}
const propTypes = {}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 0 0 24px;
  max-width: 296px;
  ${({ theme }) => theme.media.phone} {
    padding: 32px 0 20px;
  }
`

const StepButton = styled(Button)`
  width: 296px;
  margin-top: 32px;
  & + & {
    margin-top: 8px;
  }
`

const optionsList = [
  { label: 'Academic or student', value: 'academicOrStudent' },
  { label: 'Medical professional', value: 'medicalProfessional' },
  { label: 'Marketing professional', value: 'marketingProfessional' },
  { label: 'Investor', value: 'investor' },
  { label: 'Government or NGO employee', value: 'governmentOrNGOemployee' },
  { label: 'Entrepreneur', value: 'entrepreneur' },
  { label: 'Other', value: 'other' },
]

export const DescribeStep = props => {
  const [isFetching, setIsFetching] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { setStep, formData, setFormData, editMode, close } = props
  const { user, setSession, loadProfile } = useSession()

  const submit = async values => {
    setIsFetching(true)
    if (!values?.whatBestDescribesYou) {
      enqueueSnackbar('Chose one', { variant: 'error' })
      setIsFetching(false)
      return
    }

    const body = {
      ...values,
    }

    api
      .put('/users/setup-what-best-describes-you', body, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      })
      .then(async function ({ data }) {
        if (editMode) {
          await loadProfile()
          close()
        } else {
          setStep('welcome')
          setFormData(data)
          setSession({ ...user, isProfileComplete: true })
        }
      })
      .catch(function (e) {
        setIsFetching(false)
        const message = e?.response?.data?.message
        if (R.is(Array, message)) {
          enqueueSnackbar(message[0], { variant: 'error' })
        } else {
          enqueueSnackbar(message, { variant: 'error' })
        }
      })
  }

  return (
    <Step {...props}>
      <Form onSubmit={submit} initialValues={formData}>
        <Container>
          <Form.Radio
            type="radio"
            name="whatBestDescribesYou"
            options={optionsList}
          />
          <StepButton disabled={isFetching} loading={isFetching} type="submit">
            Continue
          </StepButton>
          {/* <StepButton
            onClick={() => setStep('setup')}
            type="button"
            variant="text"
            disabled={isFetching}
          >
            Back
          </StepButton> */}
        </Container>
      </Form>
    </Step>
  )
}

DescribeStep.defaultProps = defaultProps
DescribeStep.propTypes = propTypes

export default DescribeStep
