import React, { useState } from 'react'
import styled from 'styled-components'
import {
  ZeroStep,
  EmailSignIn,
  EmailPassword,
  AccountDetails,
  SetupProfile,
  DescribeStep,
  UsernameStep,
  Preferences,
  Welcome,
} from './steps'

const defaultProps = {}
const propTypes = {}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;
  ${({ theme }) => theme.media.phone} {
    height: 100%;
  }
`

const StepMap = {
  zero: ZeroStep,
  emailIn: EmailSignIn,
  emailPassword: EmailPassword,
  details: AccountDetails,
  setup: SetupProfile,
  describe: DescribeStep,
  username: UsernameStep,
  preference: Preferences,
  welcome: Welcome,
}

export const AuthSteps = ({
  defaultStep = 'zero',
  defaultValues = {},
  close,
  editMode,
  stepCustomConfig = {},
}) => {
  const [crrStep, setStep] = useState(defaultStep)
  const [formData, setFormData] = useState(defaultValues)

  const customStepConfig = stepCustomConfig[crrStep] || {}

  const stepConfig = {
    crrStep,
    setStep,
    formData,
    setFormData,
    editMode,
    close,
    ...customStepConfig,
  }

  const Step = StepMap[crrStep] || StepMap.zero

  return (
    <Container>
      <Step {...stepConfig} />
    </Container>
  )
}

AuthSteps.defaultProps = defaultProps
AuthSteps.propTypes = propTypes

export default AuthSteps
