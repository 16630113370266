import React from 'react'
import styled, { css } from 'styled-components'
import { getAlphaRgb } from 'components/layouts'

const defaultProps = {}
const propTypes = {}

const typeMap = {
  article: css`
    background-color: ${({ theme }) => theme.colors.accentY};
    background: ${({ theme }) => getAlphaRgb(theme.colors.accentY, 0.36)};
    color: ${({ theme }) => theme.colors.black};

    &::after {
      content: 'Article';
    }
  `,
  amq: css`
    background-color: ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => getAlphaRgb(theme.colors.primary, 0.36)};
    color: ${({ theme }) => theme.colors.black};

    &::after {
      content: 'Question';
    }
  `,
  post: css`
    background: ${({ theme }) => getAlphaRgb(theme.colors.accentMint, 0.36)};
    color: ${({ theme }) => theme.colors.black};

    &::after {
      content: 'Post';
    }
  `,
  group: css`
    background: ${({ theme }) => getAlphaRgb(theme.colors.accentLilac, 0.36)};
    color: ${({ theme }) => theme.colors.black};
    width: fit-content;
    padding: 0 6px;
    &::after {
      content: ${({ label }) => `'${label}'`};
    }
  `,
  physician: css`
    background: ${({ theme }) => getAlphaRgb(theme.colors.accentY, 0.56)};
    color: ${({ theme }) => theme.colors.black};
    width: fit-content;
    padding: 0 6px;
    &::after {
      content: ${({ label }) => `'${label}'`};
    }
  `,
}

const Container = styled.div`
  ${({ theme }) => theme.font.body2};
  background: ${({ theme }) => getAlphaRgb(theme.colors.grey2, 0.36)};
  color: ${({ theme }) => theme.colors.grey};
  border-radius: 2px;
  font-size: 12px;
  line-height: 15px;
  margin-left: 10px;
  padding: 0 3px;
  display: flex;
  width: 62px;
  align-items: center;
  justify-content: center;

  ${({ type }) => typeMap[type] || ''}

  ${({ size }) =>
    size === 'large' &&
    css`
      font-size: 18px;
      line-height: 21px;
      padding: 5px 8px;
    `}
`

export const FeedBadge = props => {
  return <Container {...props} />
}

FeedBadge.defaultProps = defaultProps
FeedBadge.propTypes = propTypes

export default FeedBadge
