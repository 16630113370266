import * as R from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from 'components/elements'
import { Form } from 'components/collections'
import api from 'shared/api'
import Step from '../../layout/Step'

const defaultProps = {
  title: 'Continue with email',
  desc: 'Individuals at home that are reading our content for personal health care or other non-professional reasons.',
}
const propTypes = {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 0 44px;
  max-width: 296px;
  margin: 0 auto;
  ${({ theme }) => theme.media.phone} {
    padding-top: 32px;
  }
`

const StepButton = styled(Button)`
  width: 296px;
  margin-top: 32px;
  & + & {
    margin-top: 8px;
  }
`

export const EmailSignIn = props => {
  const [isFetching, setIsFetching] = useState(false)
  const { setStep, formData, setFormData } = props

  const submit = async values => {
    setIsFetching(true)
    await api
      .post('/auth/email/verify', values, null)
      .then(function ({ data }) {
        setFormData(R.mergeLeft({ ...values, ...data }))
        setStep('emailPassword')
      })
      .catch(function (error) {
        setIsFetching(false)
        if (error?.response?.status === 404) {
          setFormData(R.mergeLeft(values))
          setStep('details')
        }
      })
  }

  return (
    <Step {...props}>
      <Form
        onSubmit={submit}
        initialValues={{
          email: '',
          ...formData,
        }}
      >
        <Container>
          <Form.Input
            name="email"
            label="E-mail"
            type="email"
            autoFocus
            required
          />
          <StepButton disabled={isFetching} loading={isFetching} type="submit">
            Sign In
          </StepButton>
          <StepButton
            onClick={() => setStep('zero')}
            type="button"
            variant="text"
            disabled={isFetching}
          >
            Back to all options
          </StepButton>
        </Container>
      </Form>
    </Step>
  )
}

EmailSignIn.defaultProps = defaultProps
EmailSignIn.propTypes = propTypes

export default EmailSignIn
