import * as R from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import {
  MdFacebook,
  MdLink,
  MdOutlineMoreHoriz,
  MdEmail,
  MdEdit,
} from 'react-icons/md'
import { TiSocialTwitter } from 'react-icons/ti'
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share'
import { useSnackbar } from 'notistack'

const defaultProps = {}
const propTypes = {}

const Container = styled.div`
  display: flex;
`
const ShareButton = styled.div`
  width: 25px;
  height: 25px;
  font-size: 26px;
  color: ${({ theme }) => theme.colors.orange};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & + & {
    margin-left: 16px;
  }
`

export const ShareBar = ({
  url = '/',
  quote = '',
  disableMenu,
  disableShare,
  actions = [],
  onEdit,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Container>
      {!disableShare && (
        <>
          <FacebookShareButton url={url} quote={quote}>
            <ShareButton>
              <MdFacebook />
            </ShareButton>
          </FacebookShareButton>
          <TwitterShareButton
            style={{ marginLeft: 16 }}
            url={url}
            title={quote}
          >
            <ShareButton>
              <TiSocialTwitter />
            </ShareButton>
          </TwitterShareButton>
          <EmailShareButton
            subject="You may be interested"
            body={quote}
            url={`\n${url}`}
            style={{ marginLeft: 16 }}
          >
            <ShareButton>
              <MdEmail />
            </ShareButton>
          </EmailShareButton>
          <ShareButton
            style={{ marginLeft: 16, fontSize: 22 }}
            onClick={() => {
              navigator.clipboard.writeText(`${quote} ${url}`)
              enqueueSnackbar('Copied to clipboard!!', { variant: 'success' })
            }}
          >
            <MdLink />
          </ShareButton>
        </>
      )}
      {onEdit && (
        <Tooltip title="Edit" placement="top">
          <ShareButton
            onClick={onEdit}
            style={{ fontSize: 22, color: '#007CFF' }}
            id="edit-button"
            key={'edit-button'}
          >
            <MdEdit key="edit-icon" />
          </ShareButton>
        </Tooltip>
      )}
      {!disableMenu && !R.isEmpty(actions) && (
        <>
          <ShareButton
            id="options-button"
            key={'options-button'}
            onClick={handleClick}
          >
            <MdOutlineMoreHoriz key="options-icon" />
          </ShareButton>
          <Menu
            id={`article-menu-${url}`}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {actions.map(
              opt =>
                opt?.label && (
                  <MenuItem
                    key={`menuItem-${opt?.label}`}
                    onClick={() => {
                      opt?.onClick()
                      handleClose()
                    }}
                  >
                    {opt?.label}
                  </MenuItem>
                )
            )}
          </Menu>
        </>
      )}
    </Container>
  )
}

ShareBar.defaultProps = defaultProps
ShareBar.propTypes = propTypes

export default ShareBar
