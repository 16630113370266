import React from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { Button } from 'components/elements'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
`

const Desc = styled.div`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.grey};
`

const TakeHomeButton = styled(Button)`
  margin-top: 30px;
`

export default function Custom404() {
  const router = useRouter()
  return (
    <Container>
      <h1>{"Whoa - that's an error!"}</h1>
      <Desc>Might be a glitch in the matrix - or something simpler.</Desc>
      <TakeHomeButton onClick={() => router.push('/')}>
        Take me home
      </TakeHomeButton>
    </Container>
  )
}
