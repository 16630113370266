export const sizes = {
  desktop: 1199,
  tablet: 1024,
  tabletMini: 769,
  phone: 575,
}

export const media = {
  desktop: `@media screen and (max-width: ${sizes.desktop}px)`,
  tablet: `@media screen and (max-width: ${sizes.tablet}px)`,
  tabletMini: `@media screen and (max-width: ${sizes.tabletMini}px)`,
  phone: `@media screen and (max-width: ${sizes.phone}px)`,
}
