import React from 'react'
import styled from 'styled-components'
import Script from 'next/script'
import { GoogleLogin } from 'react-google-login'
import { useSession } from 'context/Session'
import { Button } from 'components/elements'
import Step from '../../layout/Step'

// import { loginFace } from './modules/datasource'
const facebookScriptUrl = `https://connect.facebook.net/pt_BR/sdk.js#xfbml=1&version=v12.0&appId=${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}&autoLogAppEvents=1`

const defaultProps = {
  title: 'Get Started',
  desc: 'The fastest way to sign up - we will never post on your account without asking.',
}
const propTypes = {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0 44px;
  margin: 0 auto;
  ${({ theme }) => theme.media.phone} {
    padding: 32px 0 20px;
  }
`

const LoginOption = styled(Button)`
  width: 296px;
  & + & {
    margin-top: 16px;
  }
`

const Info = styled.div`
  text-align: center;
  margin-top: 32px;
  color: ${({ theme }) => theme.colors.grey};
  ${({ theme }) => theme.font.label}
  padding: 0 33px;
  ${({ theme }) => theme.media.phone} {
    padding: 0 5px;
  }
`

export const Zero = props => {
  const {
    // loginFacebook,
    loginGoogle,
    // getRequestTwitterToken
  } = useSession()
  const { setStep, close } = props

  return (
    <Step {...props}>
      <Script
        async
        defer
        src="https://apis.google.com/js/platform.js?onload=renderButton"
      />
      <Script
        async
        defer
        crossorigin="anonymous"
        src={facebookScriptUrl}
        nonce="OIHuOnDP"
      />
      <Container>
        <GoogleLogin
          clientId={process.env.NEXT_PUBLIC_GOOGLE_APP_ID}
          render={renderProps => (
            <LoginOption
              id="customBtn"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              Continue with Google
            </LoginOption>
          )}
          buttonText="Login"
          onSuccess={e => {
            loginGoogle(e, close)
          }}
          cookiePolicy={'single_host_origin'}
        />
        {/* <LoginOption
          onClick={() => {
            loginFacebook(close)
          }}
        >
          Continue with Facebook
        </LoginOption>
        <LoginOption onClick={getRequestTwitterToken}>
          Continue with Twitter
        </LoginOption> */}
        <LoginOption onClick={() => setStep('emailIn')}>
          Continue with Email
        </LoginOption>
        <Info>
          Click “Sign Up” to agree to TSN&apos;s Terms of Service and
          acknowledge that TSN&apos;s Privacy Policy applies to you.
        </Info>
      </Container>
    </Step>
  )
}

Zero.defaultProps = defaultProps
Zero.propTypes = propTypes

export default Zero
