import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { sizes } from 'components/layouts/Theme/media'

export const getCloudFlareImgVariant = (imgSrc, variants = {}) => {
  if (typeof window !== 'undefined' && !R.isEmpty(variants)) {
    const windowWidth = window.innerWidth

    if (windowWidth <= sizes.phone) {
      return imgSrc.replace(
        '/public',
        `/${variants.phone || variants?.default || 'public'}`
      )
    }
    if (windowWidth <= sizes.tabletMini) {
      return imgSrc.replace(
        '/public',
        `/${variants.tabletMini || variants?.default || 'public'}`
      )
    }
    if (windowWidth <= sizes.tablet) {
      return imgSrc.replace(
        '/public',
        `/${variants.tablet || variants?.default || 'public'}`
      )
    }
    if (windowWidth <= sizes.desktop) {
      return imgSrc.replace(
        '/public',
        `/${variants.desktop || variants?.default || 'public'}`
      )
    }
    if (variants?.default) {
      return imgSrc.replace('/public', `/${variants.default}`)
    }
  }
  if (variants?.default) {
    return imgSrc.replace('/public', `/${variants.default}`)
  }
  return imgSrc
}

const withSizes = Component => {
  const ComponentWithSizes = props => {
    const [windowWidth, setWindowWidth] = useState(undefined)
    useEffect(() => {
      function handleResize() {
        typeof window !== 'undefined' && setWindowWidth(window.innerWidth)
      }
      if (typeof window !== 'undefined') {
        window.addEventListener('resize', handleResize)

        handleResize()

        return () => window.removeEventListener('resize', handleResize)
      }
    }, [])

    const mediaValues = {
      isMobile: windowWidth <= sizes.phone,
      mediaDesc: 'desktopHD',
    }
    if (windowWidth <= sizes.desktop) {
      mediaValues.mediaDesc = 'desktop'
    }
    if (windowWidth <= sizes.tablet) {
      mediaValues.mediaDesc = 'tablet'
    }
    if (windowWidth <= sizes.tabletMini) {
      mediaValues.mediaDesc = 'tabletMini'
    }
    if (windowWidth <= sizes.phone) {
      mediaValues.mediaDesc = 'phone'
    }

    const checkMedia = o => mediaValues?.mediaDesc === o

    return (
      !R.isNil(windowWidth) && (
        <Component {...mediaValues} checkMedia={checkMedia} {...props} />
      )
    )
  }

  return ComponentWithSizes
}

export default withSizes
