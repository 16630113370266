import { createGlobalStyle, css } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  ::selection {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
  }
  
  body {
    overflow-y: scroll;
    background: ${({ theme }) => theme.colors.lightGrey};
    color: ${({ theme }) => theme.colors.black};
    ${({ theme }) => theme.font.body};
    margin: 0;
    padding: 0;
    font-family: -apple-system,BlinkMacSystemFont,
    "Segoe UI",Roboto,"Helvetica Neue",Arial,
    "Noto Sans","Liberation Sans",sans-serif,
    "Apple Color Emoji","Segoe UI Emoji",
    "Segoe UI Symbol","Noto Color Emoji";
  }
  .z-alert {
    z-index: 10000 !important;
  }
  #nprogress .bar {
    background: ${({ theme }) => theme.colors.primary} !important;
    height: 3px;
  }
  a {
    color: unset;
    text-decoration: unset;
  }
`

export const Ellipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`
