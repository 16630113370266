import React from 'react'
import styled from 'styled-components'
import MuiCheckbox from '@mui/material/Checkbox'

const defaultProps = {}
const propTypes = {}

const StyledCheck = styled(MuiCheckbox)`
  .MuiCheckbox-root {
    font-size: 24px;
  }
`

export const Checkbox = props => {
  return <StyledCheck {...props} />
}

Checkbox.defaultProps = defaultProps
Checkbox.propTypes = propTypes

export default Checkbox
