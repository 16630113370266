import { not, isNil } from 'ramda'
import React, { useState, useEffect } from 'react'
import NProgress from 'nprogress'
import { useSession } from 'context/Session'
import { Loading } from 'components/elements'

import { loadIsFollowing, follow, unfollow } from './modules/datasource'

import { ToggleButton } from './FollowButton.styles'

const defaultProps = {
  following: false,
  size: 'small',
}
const propTypes = {}

export const FollowButton = props => {
  const {
    isFollowing: defaultFollowing,
    tagSlug,
    groupId,
    username,
    show,
    customLabels = {
      follow: 'Follow',
      following: 'Following',
      unfollow: 'Unfollow',
    },
    ...rest
  } = props
  const { accessToken } = useSession()
  const shouldLoad =
    accessToken && (groupId || tagSlug || username) && isNil(defaultFollowing)
  const [isFetching, setIsFetching] = useState(shouldLoad)
  const [isFollowing, setIsFollowing] = useState(defaultFollowing)

  useEffect(async () => {
    if (shouldLoad) {
      setIsFetching(true)
      try {
        const isFollow = await loadIsFollowing({
          groupId,
          tagSlug,
          username,
          accessToken,
        })
        setIsFollowing(isFollow)
        setIsFetching(false)
      } catch (e) {
        setIsFetching(false)
      }
    }
  }, [])

  useEffect(() => {
    setIsFollowing(defaultFollowing)
  }, [defaultFollowing])

  if (!accessToken && !show) {
    return null
  }

  const toggleFollow = async () => {
    NProgress.start()
    if (isFollowing) {
      await unfollow({ accessToken, username, tagSlug, groupId })
    } else {
      await follow({ accessToken, username, tagSlug, groupId })
    }
    setIsFollowing(not)
    NProgress.done()
  }

  if (isFetching) {
    return <Loading style={rest?.style} size={20} />
  }

  return (
    <ToggleButton
      onClick={toggleFollow}
      isActive={isFollowing}
      customLabels={customLabels}
      {...rest}
    />
  )
}

FollowButton.defaultProps = defaultProps
FollowButton.propTypes = propTypes

export default FollowButton
