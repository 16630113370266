import React from 'react'
import MuiRadio from '@mui/material/Radio'
import MuiRadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import styled from 'styled-components'

const defaultProps = {
  options: [],
}
const propTypes = {}

const StyledGroup = styled(MuiRadioGroup)``
const StyledRadio = styled(MuiRadio)``

const Radio = ({ options, ...rest }) => {
  return (
    <StyledGroup {...rest}>
      {options.map(item => (
        <FormControlLabel
          key={item?.value}
          value={item?.value}
          label={item?.label}
          control={<StyledRadio />}
        />
      ))}
    </StyledGroup>
  )
}

Radio.defaultProps = defaultProps
Radio.propTypes = propTypes

export default Radio
