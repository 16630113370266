import React from 'react'
import ReactDOM from 'react-dom'
import Fade from '@mui/material/Fade'
import * as S from './Modal.styles'

const defaultProps = {
  closeOnOutside: true,
}
const propTypes = {}

export const Modal = props => {
  const {
    isOpen = true,
    close,
    title,
    children,
    className,
    cssHeader,
    cssContent,
    closeOnOutside,
    disableClose,
  } = props

  const modalClose = () => {
    close && close()
  }
  const overlayClick = () => {
    if (closeOnOutside) {
      close && close()
    }
  }

  return (
    process.browser &&
    ReactDOM.createPortal(
      <>
        <Fade in={isOpen} timeout={400} appear unmountOnExit>
          <S.Container>
            <S.Overlay onClick={overlayClick} />
            <S.DisableBodyOverflow />
            <S.Modal className={className}>
              <S.Header css={cssHeader}>
                {!disableClose && <S.Close onClick={modalClose} />}
                {title && <S.Title>{title}</S.Title>}
              </S.Header>
              <S.Content css={cssContent} className="modal-content">
                {React.cloneElement(children, props)}
              </S.Content>
            </S.Modal>
          </S.Container>
        </Fade>
      </>,
      document.getElementById('app-modal')
    )
  )
}

Modal.defaultProps = defaultProps
Modal.propTypes = propTypes

export default Modal
