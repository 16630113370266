import * as R from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useSnackbar } from 'notistack'
import { useSession } from 'context/Session'
import { Button } from 'components/elements'
import { Form } from 'components/collections'
import api from 'shared/api'
import * as yup from 'yup'

import Step from '../../layout/Step'

const defaultProps = {
  title: (
    <>
      Complete your account with
      <br /> a few more details
    </>
  ),
}
const propTypes = {}

const Container = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  padding: 0 0 44px;
  max-width: 296px;
  width: 100%;
  margin: 0 auto;

  ${({ theme }) => theme.media.phone} {
    padding-top: 32px;
  }
`

const StepButton = styled(Button)`
  grid-column: 1 / 3;
`

const validationSchema = yup.object({
  username: yup
    .string()
    .required('Required field')
    .min(3, 'At least 3 characters')
    .max(25, 'Shorter than or equal to 25 characters'),
})

const LogoutOption = styled.div`
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.grey};
  cursor: pointer;
  grid-column: 1 /3;
  ${({ theme }) => theme.font.body2}
  &:hover {
    text-decoration: underline;
  }
`

export const UsernameStep = props => {
  const { setStep, setModalConfig } = props
  const { enqueueSnackbar } = useSnackbar()
  const [isFetching, setIsFetching] = useState(false)
  const { accessToken, clearSession } = useSession()

  const submit = async values => {
    setIsFetching(true)
    await api
      .put('/users/setup-account', values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function () {
        setModalConfig({})
        setStep('preference')
      })
      .catch(function (e) {
        setIsFetching(false)
        const message =
          e?.response?.data?.message ||
          e?.response?.data ||
          'Something went wrong'
        if (R.is(Array, message)) {
          enqueueSnackbar(message[0], { variant: 'error' })
        } else {
          enqueueSnackbar(message, { variant: 'error' })
        }
      })
  }

  return (
    <Step {...props}>
      <Form
        onSubmit={submit}
        style={{ width: '100%' }}
        validationSchema={validationSchema}
        initialValues={{
          username: '',
          receiveEmails: false,
          registeredPhysician: false,
        }}
      >
        <Container>
          <Form.Input
            name="username"
            label="Username"
            helperText="You cannot change your username"
            style={{ gridColumn: '1 / 3' }}
            inputProps={{
              style: { textTransform: 'lowerCase' },
            }}
            required
            disabled={isFetching}
          />
          <Form.Checkbox
            name="receiveEmails"
            label={
              <>
                Agree to receive email notifications <br />
                (can be changed later in settings)
              </>
            }
            labelStyles={{ gridColumn: '1 / 3' }}
            styles={{
              fontSize: 14,
              marginLeft: 0,
            }}
          />
          <Form.Checkbox
            name="registeredPhysician"
            label={
              <>
                {"I'm a registered physician."} <br />
                (This info is anonymous)
              </>
            }
            labelStyles={{ gridColumn: '1 / 3', textAlign: 'left' }}
            styles={{
              fontSize: 14,
              marginLeft: 0,
            }}
          />
          <StepButton disabled={isFetching} loading={isFetching} type="submit">
            Continue
          </StepButton>
          <LogoutOption onClick={clearSession}>Logout</LogoutOption>
        </Container>
      </Form>
    </Step>
  )
}

UsernameStep.defaultProps = defaultProps
UsernameStep.propTypes = propTypes

export default UsernameStep
