import api from 'shared/api'

export const uploadImage = async ({ imageSrc, accessToken }) => {
  const { data: postData } = await api.post('/upload/image', null, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })

  const formData = new FormData()

  formData.append('file', imageSrc)

  await api.post(postData?.postUrl, formData)

  return postData?.id
}
