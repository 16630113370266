import * as R from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useSnackbar } from 'notistack'
import { useSession } from 'context/Session'
import { Button } from 'components/elements'
import { Form } from 'components/collections'
import * as yup from 'yup'
import Step from '../../layout/Step'

const defaultProps = {
  title: (
    <>
      Create your account with
      <br /> a few more details
    </>
  ),
}
const propTypes = {}

const Container = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  padding: 56px 0 44px;
  max-width: 296px;
  width: 100%;
  margin: 0 auto;

  ${({ theme }) => theme.media.phone} {
    padding-top: 32px;
  }
`

const StepButton = styled(Button)`
  grid-column: 1 / 3;
`

const validationSchema = yup.object({
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  firstName: yup
    .string()
    .required('Required field')
    .min(3, 'Must have at least 3 characters'),
  lastName: yup
    .string()
    .required('Required field')
    .min(3, 'Must have at least 3 characters'),
  username: yup
    .string()
    .required('Required field')
    .min(3, 'At least 3 characters')
    .max(25, 'Shorter than or equal to 25 characters'),
})

export const AccountDetails = props => {
  const { enqueueSnackbar } = useSnackbar()
  const [isFetching, setIsFetching] = useState(false)
  const { setStep, formData, close } = props
  const { loginEmail } = useSession()

  const submit = async values => {
    setIsFetching(true)
    await loginEmail(values, true)
      .then(function () {
        close()
      })
      .catch(function (e) {
        setIsFetching(false)
        const message =
          e?.response?.data?.message ||
          e?.response?.data ||
          'Something went wrong'
        if (R.is(Array, message)) {
          enqueueSnackbar(message[0], { variant: 'error' })
        } else {
          enqueueSnackbar(message, { variant: 'error' })
        }
      })
  }

  return (
    <Step {...props}>
      <Form
        onSubmit={submit}
        style={{ width: '100%' }}
        validationSchema={validationSchema}
        initialValues={{
          firstName: '',
          lastName: '',
          password: '',
          confirmPassword: '',
          ...formData,
          receiveEmails: false,
          registeredPhysician: false,
        }}
      >
        <Container>
          <Form.Input name="firstName" label="First name" required autoFocus />
          <Form.Input name="lastName" label="Last name" required />
          <Form.Input
            name="username"
            label="Username"
            helperText="You cannot change your username"
            style={{ gridColumn: '1 / 3' }}
            inputProps={{
              style: { textTransform: 'lowerCase' },
            }}
            required
            disabled={isFetching}
          />
          <Form.Input
            name="password"
            label="Password"
            type="password"
            required
            style={{ gridColumn: '1 / 3' }}
            inputProps={{
              autocomplete: 'new-password',
            }}
          />
          <Form.Input
            name="confirmPassword"
            label="Confirm password"
            type="password"
            required
            style={{ gridColumn: '1 / 3' }}
            inputProps={{
              autocomplete: 'new-password',
            }}
          />
          <Form.Checkbox
            name="receiveEmails"
            label={
              <>
                Agree to receive email notifications <br />
                (can be changed later in settings)
              </>
            }
            labelStyles={{
              gridColumn: '1 / 3',
              textAlign: 'left',
            }}
            styles={{
              fontSize: 14,
              marginLeft: 0,
            }}
          />
          <Form.Checkbox
            name="registeredPhysician"
            label={
              <>
                {"I'm a registered physician."} <br />
                (This info is anonymous)
              </>
            }
            labelStyles={{ gridColumn: '1 / 3', textAlign: 'left' }}
            styles={{
              fontSize: 14,
              marginLeft: 0,
            }}
          />
          <StepButton disabled={isFetching} loading={isFetching} type="submit">
            Continue
          </StepButton>
          <StepButton
            onClick={() => setStep('emailIn')}
            type="button"
            variant="text"
            disabled={isFetching}
          >
            Back
          </StepButton>
        </Container>
      </Form>
    </Step>
  )
}

AccountDetails.defaultProps = defaultProps
AccountDetails.propTypes = propTypes

export default AccountDetails
