import * as R from 'ramda'
import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'
import { Checkbox } from 'components/elements'
import FormControlLabel from '@mui/material/FormControlLabel'

const defaultProps = {}
const propTypes = {}

const StyledControl = styled(FormControlLabel)`
  width: 100%;
`

const StyledCheck = styled(Checkbox)``

const StyledLabel = styled.div`
  margin-left: 16px;
  user-select: none;
`

export function fieldToCheckbox({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting },
  onBlur,
  ...props
}) {
  return {
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name)
      },
    ...field,
    ...props,
  }
}

export const FormCheck = props => {
  const { name, value, inputValidation, label, asRadio, labelStyles, styles } =
    props

  return (
    <Field name={name} value={value} validate={inputValidation}>
      {({ field, form }) => {
        const isSelected = asRadio
          ? R.includes(value, field?.value || [])
          : field?.value

        return (
          <StyledControl
            style={labelStyles}
            control={
              <StyledCheck
                {...fieldToCheckbox({ ...props, field, form })}
                checked={isSelected}
              />
            }
            label={
              <StyledLabel style={styles} isSelected={isSelected}>
                {label}
              </StyledLabel>
            }
          />
        )
      }}
    </Field>
  )
}

FormCheck.defaultProps = defaultProps
FormCheck.propTypes = propTypes

export default FormCheck
