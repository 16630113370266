import * as R from 'ramda'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useSnackbar } from 'notistack'
import { Button } from 'components/elements'
import { Form } from 'components/collections'
import { getAlphaRgb } from 'components/layouts'
import { useSession } from 'context/Session'
import api from 'shared/api'
import Step from '../../layout/Step'

import { uploadImage } from './modules/datasource'

const defaultProps = {
  title: 'Setup your profile',
}
const propTypes = {}

const Container = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  padding: 0 0 44px;
  max-width: 296px;
  width: 100%;
  margin: 0 auto;

  ${({ theme }) => theme.media.phone} {
    padding-top: 32px;
  }
`

const StepButton = styled(Button)`
  width: 296px;
  margin-top: 32px;
  & + & {
    margin-top: -8px;
  }
`

const StyledUpload = styled(Form.Upload)`
  width: unset;
  margin: 0 auto;
`

const Avatar = styled.div`
  position: relative;
  overflow: hidden;
  height: 112px;
  width: 112px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  margin-bottom: 8px;
  ${({ src }) =>
    src &&
    css`
      background-image: ${`url(${src})`};
      background-size: cover;
      background-position: center;
      &:hover {
        &::after {
          content: 'Edit';
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: ${({ theme }) => getAlphaRgb(theme.colors.grey, 0.5)};
        }
      }
    `};
`

export const Setup = props => {
  const [isFetching, setIsFetching] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { setStep, formData, setFormData, editMode } = props
  const { user, accessToken, setSession, loadProfile } = useSession()

  const submit = async values => {
    setIsFetching(true)
    setFormData(R.mergeLeft(values))
    const imageSrc = R.head(values?.profileImage || [])

    const profileImage =
      imageSrc && (await uploadImage({ imageSrc, accessToken }))

    const body = {
      ...formData,
      ...values,
      profileImage,
    }

    api
      .put('/users/setup-profile', body, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      })
      .then(async function ({ data }) {
        if (editMode) {
          await loadProfile()
          close()
        } else {
          setStep('describe')
          setFormData(data)
          const { data: profile } = await loadProfile()
          setSession({ ...user, profile, isProfileComplete: true })
        }
      })
      .catch(function (e) {
        setIsFetching(false)
        const message = e?.response?.data?.message
        if (R.is(Array, message)) {
          enqueueSnackbar(message[0], { variant: 'error' })
        } else {
          enqueueSnackbar(message, { variant: 'error' })
        }
      })
  }

  return (
    <Step {...props}>
      <Form
        onSubmit={submit}
        style={{ width: '100%' }}
        initialValues={{
          company: '',
          position: '',
          bio: '',
          ...formData,
          singleLineStatement: formData?.singleLineStatement || '',
        }}
      >
        <Container>
          <StyledUpload
            name="profileImage"
            renderInput={(f = []) => {
              const previewUrl = R.head(f)
              return (
                <Avatar src={previewUrl?.preview || (!R.isEmpty(f) && f)}>
                  {previewUrl ? '' : 'Add photo'}
                </Avatar>
              )
            }}
          />
          <Form.Input
            name="company"
            label="Organization"
            required
            disabled={isFetching}
          />
          <Form.Input
            name="position"
            label="Position"
            required
            disabled={isFetching}
          />
          <Form.Input
            name="singleLineStatement"
            label="Create a short tagline for your writing"
            inputProps={{
              maxLength: 150,
            }}
            disabled={isFetching}
          />
          <Form.Input
            name="bio"
            label="Bio (max 600 characters)"
            multiline
            minRows={3}
            inputProps={{
              maxLength: 600,
            }}
            required
            disabled={isFetching}
          />
          <StepButton disabled={isFetching} loading={isFetching} type="submit">
            Continue
          </StepButton>
          {/* <StepButton
            onClick={() => setStep('details')}
            type="button"
            variant="text"
          >
            Back
          </StepButton> */}
        </Container>
      </Form>
    </Step>
  )
}

Setup.defaultProps = defaultProps
Setup.propTypes = propTypes

export default Setup
