import React, { useMemo } from 'react'
import { ThemeProvider as MUITheme, createTheme } from '@mui/material/styles'
import { ThemeProvider } from 'styled-components'

import { GlobalStyle, Ellipsis } from './Utils'
import { media } from './media'
import { FontStyles } from './typography'
import { colors } from './colors'

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: '#ffffff',
    },
    black: {
      main: colors.black,
      contrastText: '#ffffff',
    },
    error: {
      main: colors.error,
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: '56px',
          'font-weight': 600,
          'font-size': '16px',
          'line-height': '24px',
          'letter-spacing': '-0.2px',
          'text-transform': 'none',
          'border-radius': '100px',
          padding: '6px 20px',
        },
      },
    },
  },
})

export const StyledThemeProvider = ({ children }) => {
  const themeColors = useMemo(
    () => ({
      ...colors,
    }),
    [colors]
  )

  const themeConfig = {
    colors: {
      ...themeColors,
    },
    font: FontStyles,
    utils: {
      ellipsis: Ellipsis,
    },
    media,
  }

  return (
    <MUITheme theme={materialTheme}>
      <ThemeProvider theme={themeConfig}>
        <GlobalStyle />
        {children}
        <div id="app-modal" />
      </ThemeProvider>
    </MUITheme>
  )
}
