import React from 'react'
import { Formik, Form } from 'formik'
import Checkbox from './Checkbox'
import Input from './Input'
import Radio from './Radio'
import Select from './Select'
import Upload from './Upload'

const FormComponent = React.forwardRef(({ children, ...rest }, ref) => {
  return (
    <Formik initialValues={{}} {...rest} innerRef={ref}>
      {typeof children === 'function' ? (
        f => <Form style={{ width: '100%' }}>{children(f)}</Form>
      ) : (
        <Form style={{ width: '100%' }}>{children}</Form>
      )}
    </Formik>
  )
})

FormComponent.Checkbox = Checkbox

FormComponent.Input = Input

FormComponent.Radio = Radio

FormComponent.Select = Select

FormComponent.Upload = Upload

FormComponent.displayName = 'Form'

export default FormComponent
